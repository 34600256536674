import React, { useEffect, useState } from "react";
import TableVideos from "../components/Tables/TableVideos";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const Home = ({ setLoading }) => {
  const { projectName } = useParams(); // Récupérer le nom du projet depuis l'URL
  const navigate = useNavigate();
  const [projectExists, setProjectExists] = useState(null);

  const API_URL = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    const checkProjectExists = async () => {
      try {
        setLoading(true); // Déclencher le chargement
        const response = await axios.post(`${API_URL}/checkProject.php`, {
          projectName: projectName,
        });

        if (response.data.exists) {
          setProjectExists(true);
        } else {
          // Si le projet n'existe pas, rediriger vers la page d'accueil
          navigate("/");
        }
      } catch (error) {
        console.error("Erreur lors de la vérification du projet:", error);
        setProjectExists(false);
      } finally {
        setLoading(false); // Désactiver le chargement
      }
    };

    if (projectName) {
      checkProjectExists(); // Vérifier si le projet existe
    } else {
      navigate("/"); // Rediriger si aucun projet n'est spécifié
    }
  }, [projectName, navigate, API_URL, setLoading]);

  const handleDownloadZip = () => {
    if (projectName) {
      const zipUrl = `${API_URL}/downloadZipByProjectName.php?projectName=${projectName}`;
      window.open(zipUrl, "_blank");
    } else {
      console.error("Nom du projet manquant");
    }
  };

  if (projectExists === null) {
    return null; // Pas encore de données
  }

  if (!projectExists) {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="error-message">
              <h3>Projet introuvable</h3>
              <p>
                Le projet que vous avez recherché n'existe pas. Vous allez être
                redirigé vers la page d'accueil.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={handleDownloadZip}
            >
              <i className="far fa-file-archive"></i>
              <small>
                <strong>&nbsp; Télécharger les scènes (.zip)</strong>
              </small>
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <TableVideos projectName={projectName} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { useNavigate } from "react-router-dom";

function Header({ reloadProjects }) {
  const API_URL = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();

  const uploadVideoNavigation = () => {
    localStorage.clear();
    navigate("/");
  };

  const deleteAllProjects = async () => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer tous les projets ? Cette action est irréversible."
    );
    if (!confirmed) return;

    try {
      const response = await fetch(`${API_URL}/deleteAllProjects.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression des projets");
      }

      const data = await response.json();

      if (data.status === 200) {
        alert("Tous les projets ont été supprimés avec succès.");
        navigate("/");
        // Appeler reloadProjects après suppression pour rafraîchir la liste dans SideNav
        reloadProjects();
      } else {
        throw new Error(data.message || "Une erreur s'est produite.");
      }
    } catch (error) {
      console.error("Erreur:", error);
      alert(
        "Échec de la suppression des projets. Veuillez réessayer plus tard."
      );
    }
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-light navbar-dark">
        <div className="collapse navbar-collapse order-3" id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={uploadVideoNavigation}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <small>
                  <strong>Importation de la vidéo</strong>
                </small>
              </button>
            </li>
          </ul>
        </div>
        <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="fas fa-chevron-circle-down fa-lg" />
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-header">
                <small>
                  <strong>
                    <i className="fas fa-cog"></i>&nbsp;Paramètres
                  </strong>
                </small>
              </span>
              <div className="dropdown-divider" />
              <a
                href="#"
                className="dropdown-item bg-danger dropdown-footer"
                onClick={deleteAllProjects}
              >
                <small>
                  <strong>
                    <i className="far fa-trash-alt"></i>&nbsp; Effacer toutes les
                    données
                  </strong>
                </small>
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt fa-lg" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;

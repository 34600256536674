// src/components/PageLayout.jsx
import React from "react";
import UploadVideo from "../pages/UploadVideo";
import Home from "../pages/Home";

function PageLayout({ onProjectCreated, setLoading }) {
  // Reçoit la prop onProjectCreated et setLoading
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">{/* Ici va le Header */}</div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <UploadVideo
              onProjectCreated={onProjectCreated}
              setLoading={setLoading}
            />{" "}
          </div>
        </section>
      </div>
    </div>
  );
}

export default PageLayout;

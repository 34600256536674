import React from "react";

function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <small>
          <strong>
            Copyright © 2024{" "}
            <a href="https://split-video.yopyo.com/">split-video.yopyo.com</a>.
          </strong>&nbsp;
          Tous droits réservés.
        </small>
        <div className="float-right d-none d-sm-inline-block">
          <small>
            <b>Version</b> 1.0.0
          </small>
        </div>
      </footer>
      <aside className="control-sidebar control-sidebar-dark"></aside>
    </div>
  );
}

export default Footer;

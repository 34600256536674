import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import PageLayout from "./components/PageLayout";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import Home from "./pages/Home";
import { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";

function App() {
  // État pour recharger les projets
  const [reloadProjects, setReloadProjects] = useState(false);
  // État de chargement pour gérer l'affichage de l'état de chargement
  const [loading, setLoading] = useState(false);

  // Fonction pour gérer la création d'un projet
  const handleProjectCreated = () => {
    setReloadProjects((prev) => !prev); // Basculer la valeur pour déclencher le rechargement
  };

  return (
    <Router>
      <div className="wrapper">
        {/* Passer la fonction handleProjectCreated en tant que prop à Header */}
        <Header reloadProjects={handleProjectCreated} />
        {/* Passer loading et setLoading en tant que props à SideNav */}
        <SideNav
          loading={loading}
          setLoading={setLoading}
          reloadProjects={handleProjectCreated}
        />
        <Routes>
          <Route
            path="/"
            element={
              <PageLayout
                onProjectCreated={handleProjectCreated} // Passer la fonction pour recharger les projets
                setLoading={setLoading} // Passer setLoading pour gérer l'état de chargement
              />
            }
          />
          <Route
            path="/video-list/:projectName"
            element={<Home setLoading={setLoading} />}
          />
        </Routes>
        <Footer />

        {/* Overlay pour griser la page pendant le chargement */}
        {loading && (
          <div
            className="overlay d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="text-center">
              <div className="mb-4">
                <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#f39c12"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              <div>
                <small>
                  <strong>Chargement ...</strong>
                </small>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;

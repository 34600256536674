import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Branding from "./Custom/Branding";

function SideNav({ reloadProjects, loading }) {
  const navigate = useNavigate();
  const location = useLocation(); // Hook pour obtenir l'emplacement actuel

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null); // Projet sélectionné

  const API_URL = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_URL}/getAllProjectName.php`);
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        } else {
          setProjects(data.projects);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [API_URL, reloadProjects]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const currentProject = pathParts[pathParts.length - 1];
    setSelectedProject(currentProject);
  }, [location.pathname]);

  const projectNavigation = (project) => {
    navigate(`/video-list/${project}`);
  };

  const deleteProject = async (projectName) => {
    const confirmed = window.confirm(
      `Êtes-vous sûr de vouloir supprimer le projet '${projectName}' ? Cette action est irréversible.`
    );
    if (!confirmed) return;

    try {
      const response = await fetch(
        `${API_URL}/deleteProjectByProjectName.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectName }),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        alert(`Le projet '${projectName}' a été supprimé avec succès.`);
        navigate("/");
        reloadProjects();
      } else {
        throw new Error(data.message || "Une erreur s'est produite.");
      }
    } catch (error) {
      console.error("Erreur:", error);
      alert("Échec de la suppression du projet. Veuillez réessayer plus tard.");
    }
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link">
          <Branding />
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item menu-is-opening menu-open">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-project-diagram text-secondary" />
                  <p>
                    <strong className="text-secondary">&nbsp;Projets</strong>
                    <i className="fas fa-angle-left right text-secondary" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  {loading ? (
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <p>
                          <small>
                            <strong>Chargement...</strong>
                          </small>
                        </p>
                      </a>
                    </li>
                  ) : projects.length > 0 ? (
                    projects.map((project) => (
                      <li
                        className="nav-item d-flex align-items-center justify-content-between mb-1"
                        key={project}
                      >
                        <a
                          href="#"
                          className={`nav-link d-flex align-items-center justify-content-between ${
                            selectedProject === project ? "active" : ""
                          }`}
                          onClick={() => projectNavigation(project)}
                        >
                          <div className="d-flex align-items-center">
                            <i className="nav-icon fas fa-folder me-2" />
                            <p style={{ minWidth: "112px" }}>
                              <small>
                                <strong>{project}</strong>
                              </small>
                            </p>
                          </div>
                          <span className="brand-text">
                            <button
                              type="button"
                              className="btn btn-warning btn-xs"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents the click event from bubbling up to the parent <a>
                                deleteProject(project);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>&nbsp;
                              <small>
                                <strong>Supprimer</strong>
                              </small>
                            </button>
                          </span>
                        </a>
                      </li>
                    ))
                  ) : (
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <p>Aucun projet disponible</p>
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default SideNav;

import React from "react";

const Branding = () => {
  return (
    <div className="brand-container">
      <img
        src="../dist/img/AdminLTELogo.svg"
        alt="Split Video Logo"
        className="brand-image bg-warning img-rounded elevation-3"
      />
      <span
        className="brand-text bg-dark rounded font-weight-light"
        style={{ width: "100%" }}
      >
        <span
          className="badge bg-warning"
          style={{ fontSize: 15, minWidth: "180px" }}
        >
          <span className="badge bg-warning" style={{ fontSize: 15 }}>
            Split
          </span>
          <span className="badge bg-dark text-warning" style={{ fontSize: 15 }}>
            Video
          </span>
        </span>
      </span>
    </div>
  );
};

export default Branding;
